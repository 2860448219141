"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const InteractiveTool = ({ onCalculateClick, }) => {
    const [dueDate, setDueDate] = (0, react_1.useState)(new Date());
    const handleDateChange = (e, type) => {
        const newDate = new Date(dueDate);
        const value = parseInt(e.target.value);
        if (type === "year")
            newDate.setFullYear(value);
        else if (type === "month")
            newDate.setMonth(value - 1);
        else if (type === "day")
            newDate.setDate(value);
        // 未来の日付を選択できないようにする
        if (newDate > new Date()) {
            alert("未来の日付は選択できません");
            return; // 新しい日付が現在より未来なら何もしない
        }
        setDueDate(newDate);
    };
    const currentYear = new Date().getFullYear();
    return (react_1.default.createElement("div", { className: "flex flex-col items-center p-4" },
        react_1.default.createElement("p", { className: "text-base mb-4 text-gray-800 font-body" }, "\u51FA\u7523\u4E88\u5B9A\u65E5\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
        react_1.default.createElement("div", { className: "flex justify-center gap-2 mb-4" },
            react_1.default.createElement("label", { htmlFor: "yearOptions", className: "sr-only" }, "\u300C\u5E74\u300D\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
            react_1.default.createElement("select", { id: "yearOptions", className: "px-4 py-2 border border-gray-300 rounded font-body text-base", value: dueDate.getFullYear(), onChange: (e) => handleDateChange(e, "year") }, Array.from({ length: 3 }).map((_, i) => (react_1.default.createElement("option", { key: currentYear - i, value: currentYear - i },
                currentYear - i,
                "\u5E74")))),
            react_1.default.createElement("label", { htmlFor: "monthOptions", className: "sr-only" }, "\u300C\u6708\u300D\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
            react_1.default.createElement("select", { id: "monthOptions", className: "px-4 py-2 border border-gray-300 rounded font-body text-base", value: dueDate.getMonth() + 1, onChange: (e) => handleDateChange(e, "month") }, Array.from({ length: 12 }).map((_, i) => (react_1.default.createElement("option", { key: i + 1, value: i + 1 },
                i + 1,
                "\u6708")))),
            react_1.default.createElement("label", { htmlFor: "dayOptions", className: "sr-only" }, "\u300C\u65E5\u300D\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
            react_1.default.createElement("select", { id: "dayOptions", className: "px-4 py-2 border border-gray-300 rounded font-body text-base", value: dueDate.getDate(), onChange: (e) => handleDateChange(e, "day") }, Array.from({ length: 31 }).map((_, i) => (react_1.default.createElement("option", { key: i + 1, value: i + 1 },
                i + 1,
                "\u65E5"))))),
        react_1.default.createElement("button", { className: "bg-pink-400 text-white my-2 py-2 px-6 rounded hover:bg-pink-600 transition-colors font-header text-lg", onClick: () => {
                onCalculateClick(dueDate);
            } }, "\u8A08\u7B97\u3059\u308B")));
};
exports.default = InteractiveTool;
