"use strict";
"use client";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
function Navbar() {
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const navi = (0, react_router_dom_1.useNavigate)();
    const items = [
        { title: "ホーム", path: "/" },
        { title: "メンタルリープとは？", path: "/mental-leap-week" },
        { title: "計算ツール", path: "/mental-leap-calculate" },
        { title: "早見表", path: "/mental-leap-simplified" },
    ];
    const listItems = items.map((item) => (react_1.default.createElement("li", { key: item.path, onClick: () => {
            navi(item.path);
            setIsOpen(!isOpen);
        }, className: "px-3 py-2 cursor-pointer rounded hover:bg-pink-100" }, item.title)));
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    return (react_1.default.createElement("div", { className: "container relative m-auto p-3 flex justify-between items-center" },
        react_1.default.createElement("div", { className: "flex items-center" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("img", { className: "h-10 w-10", src: "/dist/images/logo.webp", alt: "BabyTeru Logo" })),
            react_1.default.createElement("h1", { className: "ml-3 font-bold text-4xl leading-none text-pink-300" }, "BabyTeru")),
        react_1.default.createElement("nav", { className: isOpen ? "flex" : " hidden md:flex" },
            react_1.default.createElement("ul", { className: "z-10 p-4 flex bg-white absolute md:relative flex-col md:flex-row w-full shadow md:shadow-none text-center top-12 left-0 md:top-0 md:flex" }, listItems)),
        react_1.default.createElement("div", { className: "md:hidden" },
            react_1.default.createElement("button", { className: "flex justify-center items-center", onClick: toggleNavbar, "aria-label": "\u30E1\u30CB\u30E5\u30FC\u30DC\u30BF\u30F3" },
                react_1.default.createElement("svg", { viewBox: "0 0 24 24", width: "24", height: "24", stroke: "currentColor", strokeWidth: "2", fill: "none", strokeLinecap: "round", strokeLinejoin: "round", className: isOpen ? "hidden" : "flex" },
                    react_1.default.createElement("line", { x1: "3", y1: "12", x2: "21", y2: "12" }),
                    react_1.default.createElement("line", { x1: "3", y1: "6", x2: "21", y2: "6" }),
                    react_1.default.createElement("line", { x1: "3", y1: "18", x2: "21", y2: "18" })),
                react_1.default.createElement("svg", { viewBox: "0 0 24 24", width: "24", height: "24", stroke: "currentColor", strokeWidth: "2", fill: "none", strokeLinecap: "round", strokeLinejoin: "round", className: isOpen ? "flex" : "hidden" },
                    react_1.default.createElement("line", { x1: "18", y1: "6", x2: "6", y2: "18" }),
                    react_1.default.createElement("line", { x1: "6", y1: "6", x2: "18", y2: "18" }))))));
}
exports.default = Navbar;
