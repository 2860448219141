"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToTop = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
function scrollToTop() {
    const { pathname } = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}
exports.scrollToTop = scrollToTop;
