"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContentForWeek = void 0;
const getContentForWeek = (week) => {
    switch (week) {
        case 5:
            return {
                title: `生後1ヶ月で泣き止まないのはメンタルリープかも？生後5週目は『五感のリープ』で感覚が目覚める！`,
                paragraphs: [
                    "赤ちゃんが泣き止まないとき、驚きや不安から泣くこともありますが、実は成長の証で赤ちゃんが新しい感覚に圧倒されているサインかもしれません。",
                    "第一のメンタルリープである五感のリープとは、赤ちゃんが生まれてから1ヶ月を過ぎた頃に起こる初めてのメンタルリープのことをいいます。",
                    "この記事では、この時期のアップデート泣きで起こるとされる赤ちゃんの成長ポイントについてご紹介します！",
                ],
                imageSrc: "/dist/images/leap5.webp",
                imageAlt: "五感のリープ",
                sections: [
                    {
                        title: "五感のリープ",
                        subtitle: "感覚の目覚め",
                        content: [
                            {
                                heading: "赤ちゃんの視界が広がる瞬間",
                                text: "赤ちゃんの小さな瞳は、生まれたばかりの頃はまだぼんやりとしていますが、メンタルリープの時期には、不思議なほどにクリアになっていきます。彼らは周りの世界に目を輝かせ、色々な色や形に興味を示し始めます。おもちゃやあなたの顔を長い間、じっと見つめるその瞬間は、赤ちゃんが新しい世界を感じ取ろうとしている大切な時です。",
                            },
                            {
                                heading: "聴こえる世界が広がる",
                                text: "赤ちゃんの耳も、この頃にはさまざまな音に敏感になります。小さな音一つ一つに耳を澄ませ、あなたの声や優しい音楽に反応して、笑顔を見せたり、ときには感動の涙を流したりするかもしれません。赤ちゃんにとって、聞こえる世界は日々新しい驚きに満ちています。",
                            },
                            {
                                heading: "触れる感覚が磨かれる",
                                text: "赤ちゃんの肌は、メンタルリープ期間中にさらに敏感になります。抱きしめる手の温もりを感じ、布やおもちゃのさまざまな感触を探ることで、彼らはこの大きな世界を手足で感じ取ります。",
                            },
                            {
                                heading: "",
                                text: "この特別な時期は赤ちゃんが感じる新しい世界を共に体験する素晴らしい機会。彼らの小さな変化に寄り添い、一緒に成長の喜びを感じてくださいね。",
                            },
                        ],
                    },
                ],
            };
        case 8:
            return {
                title: `生後8週目のメンタルリープ『パターンのリープ』で世界の規則性を発見！`,
                paragraphs: [
                    "この時期、赤ちゃんは周囲の物事に規則性を見つけ始め、原子反射が少なくなることでより多くの物事を理解しようとします。",
                    "パターンのリープは、赤ちゃんが生まれてから2ヶ月頃に体験する、成長の第二のステップです。",
                    "ここでは、この時期に赤ちゃんが見せる成長のサインとその意味について掘り下げます。",
                ],
                imageSrc: "/dist/images/leap8.webp",
                imageAlt: "パターンのリープ",
                sections: [
                    {
                        title: "パターンのリープ",
                        subtitle: "世界の規則性を発見",
                        content: [
                            {
                                heading: "規則性の発見",
                                text: "生後8週頃、赤ちゃんは物事のパターンや規則性に気付き始めます。日常の繰り返しや、周囲の一定のリズムに敏感になり、それに応じて反応を示します。",
                            },
                            {
                                heading: "知的好奇心の芽生え",
                                text: "赤ちゃんはこの時期、新しい発見に対して好奇心を強く抱きます。日々の小さな変化に目を輝かせ、探求しようとする姿勢が見られるようになります。",
                            },
                            {
                                heading: "",
                                text: "この大切な時期は、赤ちゃんの知的好奇心を育むチャンス。親子で一緒に世界を探索し、その小さな発見を楽しむことで、赤ちゃんの成長をサポートしてあげてくださいね。",
                            },
                        ],
                    },
                ],
            };
        case 12:
            return {
                title: `生後12週目のメンタルリープ『推移のリープ』で世界を新たな目で見る！`,
                paragraphs: [
                    "この時期の赤ちゃんは、世界が移り変わる様子を見始め、物事の変化に興味を持ちます。生後3ヶ月頃のメンタルリープは、赤ちゃんにとって、周囲の世界がより動的で変化に富んだものになる特別な時です。",
                    "第三のメンタルリープである推移のリープは、赤ちゃんが周囲の環境や物事の変化に敏感になる時期です。彼らは光の強弱や音の強弱など、動きや変化のあるものに興味を示し始めます。",
                    "この記事では、この成長期に赤ちゃんが見せる興味深い変化と、親としてどのようにサポートできるかについてご紹介します。",
                ],
                imageSrc: "/dist/images/leap12.webp",
                imageAlt: "推移のリープ",
                sections: [
                    {
                        title: "推移のリープ",
                        subtitle: "世界を新たな目で見る",
                        content: [
                            {
                                heading: "興味あるものを追う動き",
                                text: "赤ちゃんはこの時期、目で追ったり、体をよじったりする動きが顕著になります。彼らは興味を持ったものに対して、積極的に反応し、より詳細に観察しようとします。",
                            },
                            {
                                heading: "環境の変化に敏感に",
                                text: "光や音の変化に反応することで、赤ちゃんは周囲の環境が持つリズムやパターンを理解し始めます。これらの感覚的な発見は、彼らの知的好奇心を刺激します。",
                            },
                            {
                                heading: "",
                                text: "この時期の赤ちゃんの成長を見守りながら、彼らが新たな発見をするたびに、その小さな驚きを一緒に楽しむことで、親子の絆を深めましょう。",
                            },
                        ],
                    },
                ],
            };
        case 19:
            return {
                title: `生後19週目のメンタルリープ『出来事のリープ』で世界が複雑に感じられる！`,
                paragraphs: [
                    "生後19週頃は、赤ちゃんの感覚が一段と複雑になり、これまでのリープで得た経験を組み合わせて物事を理解する時期です。赤ちゃんは、多くの五感を使って、周囲の世界をより深く感じ取るようになります。",
                    "出来事のリープでは、赤ちゃんは手で物をつかむ能力が向上し、物を口に運ぶ行動が顕著になります。また、親の居場所を探すなど、社会的な認識も発達します。",
                    "この記事では、この成長期に赤ちゃんが見せる変化と、親としてどのようにサポートするかについて探求します。",
                ],
                imageSrc: "/dist/images/leap19.webp",
                imageAlt: "出来事のリープ",
                sections: [
                    {
                        title: "出来事のリープ",
                        subtitle: "複雑な世界の理解",
                        content: [
                            {
                                heading: "手の器用さが向上",
                                text: "この時期、赤ちゃんは手の動きが器用になり、つかもうとする物を上手につかめるようになります。日々の探索で新たな技術を身に付けています。",
                            },
                            {
                                heading: "物を口に運ぶ行動",
                                text: "赤ちゃんは物を口に入れることで、その質感や形を学びます。これは、世界を理解するための重要な手段となります。",
                            },
                            {
                                heading: "社会的認識の発達",
                                text: "親の居場所を探すなど、社会的な認識も発達し、赤ちゃんは自分と他者との関係を理解し始めます。これらの行動は、社会的なつながりを形成する初期段階です。",
                            },
                            {
                                heading: "",
                                text: "赤ちゃんのこの重要な成長段階を、一緒に楽しみながら、彼らの探索をサポートし、新たな発見を共に喜びましょう。",
                            },
                        ],
                    },
                ],
            };
        case 26:
            return {
                title: `生後26週目のメンタルリープ『関係のリープ』で世界のつながりを発見！`,
                paragraphs: [
                    "生後26週頃の赤ちゃんは、物事の関係性を理解し始める大切な時期です。彼らは、目で見える物の位置関係や距離感を感じ取る能力が発達します。",
                    "関係のリープでは、赤ちゃんは見慣れない人や物に興味を持ち、じっと観察する行動が目立ちます。また、細かなパーツや、舌を使った音まねなどの新しい技術を習得します。",
                    "この記事では、赤ちゃんがこの時期に示す興味深い行動変化と、それらをサポートする方法について紹介します。",
                ],
                imageSrc: "/dist/images/leap26.webp",
                imageAlt: "関係のリープ",
                sections: [
                    {
                        title: "関係のリープ",
                        subtitle: "物事のつながりを理解する",
                        content: [
                            {
                                heading: "物の位置関係の認識",
                                text: "赤ちゃんはこの時期、物の位置関係や距離を理解することができ、見慣れないものに対する好奇心を強く示します。",
                            },
                            {
                                heading: "細かなパーツに興味を示す",
                                text: "細かいパーツや異なるテクスチャーに対する興味が増し、赤ちゃんはこれらを使って探索し、学習します。",
                            },
                            {
                                heading: "舌を使った音まね",
                                text: "舌を使っての音まねは、赤ちゃんの言語発達の初期段階です。彼らは音や言葉に興味を持ち、それを真似ることでコミュニケーション能力を高めます。",
                            },
                            {
                                heading: "",
                                text: "この興味深い成長期を通じて、赤ちゃんが新たな発見をするたびに、その驚きと喜びを一緒に共有し、彼らの探索をサポートしてください。",
                            },
                        ],
                    },
                ],
            };
        case 37:
            return {
                title: `生後37週目のメンタルリープ『分類のリープ』で物事を見分ける力が芽生える！`,
                paragraphs: [
                    "生後37週頃の赤ちゃんは、物事を分類する能力が発達する重要な時期に差し掛かります。彼らは、これまで同じと思っていたものを区別し、新しい視点で世界を見始めます。",
                    "分類のリープでは、赤ちゃんは異なる動物や物を識別し、比較することに興味を持ちます。外の世界への探索も好むようになり、新しい発見を楽しみます。",
                    "この記事では、赤ちゃんがこの時期に示す興味深い行動の変化と、それらを親としてどのようにサポートするかについて詳しく探ります。",
                ],
                imageSrc: "/dist/images/leap37.webp",
                imageAlt: "分類のリープ",
                sections: [
                    {
                        title: "分類のリープ",
                        subtitle: "新しい視点で世界を見る",
                        content: [
                            {
                                heading: "物事の区別と識別",
                                text: "この時期、赤ちゃんは異なる種類の動物や物を識別し、それらを分類する能力を発達させます。これは、認知発達の大きな一歩です。",
                            },
                            {
                                heading: "比較への興味",
                                text: "物事を比較することに興味を持ち始め、似ているものや異なるものに対して反応を示します。この行動は、彼らの分析能力と好奇心を刺激します。",
                            },
                            {
                                heading: "外の世界への探索",
                                text: "赤ちゃんは外での探索を楽しみ、新しい環境や物に対して好奇心を示します。これは、彼らの社会的な認識と世界理解を深める重要な段階です。",
                            },
                            {
                                heading: "",
                                text: "赤ちゃんがこの成長期に新たな発見をするたびに、その小さな驚きを一緒に喜び、探索の旅をサポートしてください。",
                            },
                        ],
                    },
                ],
            };
        case 46:
            return {
                title: `生後46週目のメンタルリープ『順序のリープ』で物事の流れを理解！`,
                paragraphs: [
                    "生後46週頃の赤ちゃんは、物事の一連の流れを理解し、自分で実現しようとする重要な時期です。彼らは、日常の活動において順序立てて行動する能力を発達させます。",
                    "順序のリープでは、赤ちゃんはスプーンを使って自分で食べようとしたり、流れのある遊びを好んだりするようになります。また、親の手の動作を観察し、まねをする行動が増えます。",
                    "この記事では、赤ちゃんがこの時期に示す行動の変化と、それらをどのようにサポートするかについて詳しく紹介します。",
                ],
                imageSrc: "/dist/images/leap46.webp",
                imageAlt: "順序のリープ",
                sections: [
                    {
                        title: "順序のリープ",
                        subtitle: "物事の流れを理解する",
                        content: [
                            {
                                heading: "自己主導の食事",
                                text: "赤ちゃんはスプーンを使って自分で食べることに挑戦し、自立した食事のスキルを身につけようとします。",
                            },
                            {
                                heading: "流れのある遊びへの興味",
                                text: "一連の流れを持つ遊びに興味を示し、物語や連続したアクションを楽しむようになります。",
                            },
                            {
                                heading: "親の動作の模倣",
                                text: "親の手の動きを細かく観察し、それをまねることで、赤ちゃんは新しい技術や行動パターンを学びます。",
                            },
                            {
                                heading: "",
                                text: "この時期の赤ちゃんの一歩一歩の成長を見守り、新しい能力を発見するたびに、一緒に喜びを分かち合いましょう。",
                            },
                        ],
                    },
                ],
            };
        case 55:
            return {
                title: `生後55週目のメンタルリープ『工程のリープ』で一連の作業を理解！`,
                paragraphs: [
                    "生後55週頃の赤ちゃんは、一連の作業の工程を理解し、それを実行する力が芽生える時期です。彼らは、順序立てて行動することの重要性を学び始めます。",
                    "工程のリープでは、赤ちゃんは親の作業を観察し、お手伝いをしようとする行動が目立ちます。また、必要な道具や手順を理解し、それに応じて行動します。",
                    "この記事では、赤ちゃんがこの成長期に示す興味深い行動の変化と、それらを親としてどのようにサポートするかについて詳しく探ります。",
                ],
                imageSrc: "/dist/images/leap55.webp",
                imageAlt: "工程のリープ",
                sections: [
                    {
                        title: "工程のリープ",
                        subtitle: "作業の流れを理解する",
                        content: [
                            {
                                heading: "親の作業の観察",
                                text: "赤ちゃんは親の日常の作業をじっくりと観察し、その工程を理解しようとします。これは、彼らの認知発達における重要なステップです。",
                            },
                            {
                                heading: "お手伝いの試み",
                                text: "親の作業に興味を持ち、お手伝いをしようとする赤ちゃんの行動は、彼らの協力的な態度と社会的な学習を示しています。",
                            },
                            {
                                heading: "道具や手順の理解",
                                text: "赤ちゃんは必要な道具を準備するなど、作業の手順を理解し始め、それに応じて自分の行動を調整します。",
                            },
                            {
                                heading: "",
                                text: "赤ちゃんが新たなスキルを身に付け、日々の作業を通じて学習する姿を、一緒に楽しみながらサポートしてあげましょう。",
                            },
                        ],
                    },
                ],
            };
        case 64:
            return {
                title: `生後64週目のメンタルリープ『原則のリープ』でルールや原則を理解！`,
                paragraphs: [
                    "生後64週頃の赤ちゃんは、ルールや原則を理解し求める時期に入ります。この成長段階では、自分と他人の物の区別をつける能力が発達します。",
                    "原則のリープ中、赤ちゃんは交渉や駆け引きをするなど、社会的な相互作用における新たな行動を示し始めます。彼らは自己主張をすると同時に、他者との関係におけるルールを学びます。",
                    "この記事では、赤ちゃんがこの時期に示す興味深い社会的行動の変化と、それらを親としてどのように理解しサポートするかについて探ります。",
                ],
                imageSrc: "/dist/images/leap64.webp",
                imageAlt: "原則のリープ",
                sections: [
                    {
                        title: "原則のリープ",
                        subtitle: "ルールと原則を学ぶ",
                        content: [
                            {
                                heading: "自分と他者の物の理解",
                                text: "この時期、赤ちゃんは自分の物と他人の物を区別することを学び、個人の所有権に対する認識が深まります。",
                            },
                            {
                                heading: "交渉と駆け引き",
                                text: "赤ちゃんは交渉や駆け引きを通じて、自分の意見を主張する方法を学びます。これは、彼らのコミュニケーション能力と社会的な理解の発展を示しています。",
                            },
                            {
                                heading: "",
                                text: "親として、赤ちゃんのこれらの新たな社会的技能を理解し、適切なガイダンスとサポートを提供することが大切です。彼らの成長を積極的に支援していきましょう。",
                            },
                        ],
                    },
                ],
            };
        case 75:
            return {
                title: `生後75週目のメンタルリープ『体系のリープ』で自我が芽生え、適応力が向上！`,
                paragraphs: [
                    "生後75週頃の赤ちゃんは、自我の発達が始まり、環境に合わせて適応する能力が強化される時期です。彼らは、自分の意志を表現し、自己主張を強めるようになります。",
                    "体系のリープ中、赤ちゃんは「自分がやりたい」「自分で決めたい」といった自我を表す行動が顕著になります。この時期は、個性の発達と自己認識の強化に重要な段階です。",
                    "この記事では、赤ちゃんがこの成長期に示す自我の発達と、それに伴う行動の変化について、親としてどのように理解しサポートするかについて詳しく探ります。",
                ],
                imageSrc: "/dist/images/leap75.webp",
                imageAlt: "体系のリープ",
                sections: [
                    {
                        title: "体系のリープ",
                        subtitle: "自我の芽生えと適応力の向上",
                        content: [
                            {
                                heading: "自我の表現と自己主張",
                                text: "赤ちゃんは自分の意志をはっきりと表現するようになり、個人としての自己主張を強めます。彼らは自分で決定を下したいという願望を示します。",
                            },
                            {
                                heading: "環境への適応能力の強化",
                                text: "様々な環境や状況に合わせて適応する能力が高まり、赤ちゃんはより柔軟に対応する方法を学びます。",
                            },
                            {
                                heading: "",
                                text: "この時期の赤ちゃんの成長を見守りながら、彼らの個性を尊重し、自己発達の旅をサポートしましょう。彼らの自我が芽生え、成長する様子を共に喜びましょう。",
                            },
                        ],
                    },
                ],
            };
    }
};
exports.getContentForWeek = getContentForWeek;
