"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDateToString = void 0;
function parseDateToString(date, format) {
    const yyyy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}${format}${mm}${format}${dd}`;
}
exports.parseDateToString = parseDateToString;
