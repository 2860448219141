"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DigestColumns = void 0;
exports.DigestColumns = [
    {
        columnNo: "1w0n2qebsjws",
        thumbnail: "/dist/images/columns/259364.webp",
        title: "激しく泣き続ける！生後2週間から5か月の赤ちゃんに起こるとされるパープルクライングとは？",
    },
    {
        columnNo: "cz739ix7meok",
        thumbnail: "/dist/images/columns/395281.webp",
        title: "生後6ヶ月から1歳頃まで夜泣き止まない『夜泣き』とは？",
    },
    {
        columnNo: "oh17q7h572",
        thumbnail: "/dist/images/columns/508413.webp",
        title: "メンタルリープ期間外でも泣き止まない！やってあげられたことを確認してみよう。不快を取り除く編",
    },
];
