"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getScrollAnimation() {
    return ({
        offscreen: {
            y: 150,
            opacity: 0,
        },
        onscreen: ({ duration = 2 } = {}) => ({
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                duration,
            }
        })
    });
}
exports.default = getScrollAnimation;
