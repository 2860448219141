"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDaysToNextLeap = void 0;
const constants_1 = require("../constants");
function calculateDaysToNextLeap(dueDate) {
    const today = new Date();
    const dueDateTime = dueDate.getTime();
    // 現在の日数を計算
    const diffInTime = today.getTime() - dueDateTime;
    const currentDay = Math.floor(diffInTime / (1000 * 3600 * 24));
    for (const leap of constants_1.Leaps) {
        const leapDay = leap * 7;
        if (currentDay < leapDay) {
            return leapDay - currentDay; // 次のリープまでの日数を返す
        }
    }
    return null; // すべてのリープを経過している場合
}
exports.calculateDaysToNextLeap = calculateDaysToNextLeap;
