"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDueDateRanges = void 0;
const constants_1 = require("../constants");
const FormatDate_1 = require("./FormatDate");
function calculateDueDateRanges() {
    const dueDatesForLeaps = {};
    constants_1.Leaps.forEach((week) => {
        const today = new Date();
        today.setDate(today.getDate() - week * 7);
        dueDatesForLeaps[week] = {
            start: (0, FormatDate_1.formatDateYMD)(new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000)),
            end: (0, FormatDate_1.formatDateYMD)(today),
        };
    });
    return dueDatesForLeaps;
}
exports.calculateDueDateRanges = calculateDueDateRanges;
