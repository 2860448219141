"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDateMD = exports.formatDateYMD = void 0;
function formatDateYMD(date) {
    const year = date.getFullYear();
    // getMonth()は0-11の範囲で月を返すため1を足し、月を2桁に
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0'); // 日を2桁に
    return `${year}/${month}/${day}`;
}
exports.formatDateYMD = formatDateYMD;
function formatDateMD(date) {
    const month = date.getMonth() + 1; // getMonth()は0-11の範囲で月を返すため、1を足す
    const day = date.getDate();
    return `${month}/${day}`;
}
exports.formatDateMD = formatDateMD;
